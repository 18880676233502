// --- external imports
import * as React from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Input } from '@mui/material';

// --- components
import TableLoading from '../../components/TableLoading';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';

// --- api
import { updateCompany, CompanyEmailTemplate } from '../../gateway/adminGateway';

// --- helpers
import _ from 'lodash';
import { CompanyContext } from '../../context/CompanyContext';

const initialEmailTemplates: CompanyEmailTemplate['type'][] = [
  'EMAIL_TEMPLATE_TYPE_WELCOME',
  'EMAIL_TEMPLATE_TYPE_PASSWORD_RESET',
  'EMAIL_TEMPLATE_TYPE_EMAIL_CHANGED',
];

const EmailTemplatesList: React.FC = () => {
  const company = React.useContext(CompanyContext);
  const [emailTemplatesState, setEmailTemplatesState] = React.useState<CompanyEmailTemplate[]>(
    company?.hubspotEmailTemplates ?? [],
  );

  const [submitting, setSubmitting] = React.useState(false);
  const { toaster } = useToaster();

  const findTemplate = (emailTemplates: CompanyEmailTemplate[], type: CompanyEmailTemplate['type']) => {
    return emailTemplates.find(template => template.type === type);
  };

  const initialEmailTemplatesState = React.useMemo(() => {
    const tmp: CompanyEmailTemplate[] = [];
    initialEmailTemplates.forEach(type => {

      const template = findTemplate(company?.hubspotEmailTemplates ?? [], type);

      if (!template) {
        tmp.push({ type, hubspotIdDe: '', hubspotIdFr: '' });
      } else {
        tmp.push(template);
      }
    });
    return tmp;
  }, [company]);

  React.useEffect(() => {
    setEmailTemplatesState(initialEmailTemplatesState);
  }, [initialEmailTemplatesState]);

  if (!company) {
    return <TableLoading />;
  }

  const checkIfEqual = () => {
    return emailTemplatesState.every(template => {
      const initialTemplate = initialEmailTemplatesState.find(t => t.type === template.type);
      return initialTemplate?.hubspotIdDe === template.hubspotIdDe && initialTemplate?.hubspotIdFr === template.hubspotIdFr;
    });
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto max-content',
      }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Hubspot ID - DE</TableCell>
            <TableCell>Hubspot ID - FR</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {initialEmailTemplates.map((v, index) => {
            return (
              <TableRow
                hover
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>
                  <b>{convertTypeToText(v)}</b>
                </TableCell>
                <TableCell>
                  <Input
                    value={findTemplate(emailTemplatesState, v)?.hubspotIdDe}
                    onChange={e => {
                      let tmp = structuredClone(emailTemplatesState);
                      const template = findTemplate(tmp, v);
                      tmp = tmp.filter(t => t.type !== v);
                      if (template) {
                        template.hubspotIdDe = e.currentTarget.value;
                        tmp.push(template);
                      }
                      setEmailTemplatesState(tmp);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    value={findTemplate(emailTemplatesState, v)?.hubspotIdFr}
                    onChange={e => {
                      let tmp = structuredClone(emailTemplatesState);
                      const template = findTemplate(tmp, v);
                      tmp = tmp.filter(t => t.type !== v);
                      if (template) {
                        template.hubspotIdFr = e.currentTarget.value;
                        tmp.push(template);
                      }
                      setEmailTemplatesState(tmp);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Button
        style={{
          width: '100px',
          justifySelf: 'end',
          marginTop: '10px',
        }}
        variant="contained"
        size="medium"
        disabled={checkIfEqual() || submitting}
        onClick={() => {
          const templates = emailTemplatesState.filter(template => template.hubspotIdDe || template.hubspotIdFr);
          setSubmitting(true);
          updateCompany(company?.id ?? '', {
            name: company?.name ?? '',
            supportEmailAdress: company?.supportEmailAddress ?? '',
            emailTemplates: templates,
          })
            .then(() => {
              setSubmitting(false);
              toaster('Action Successful', 'Email templates were updated.', ToastMessageTypes.SUCCESS);
            })
            .catch(() => {
              toaster('Sorry!', 'There is something wrong, unable to load email templates.', ToastMessageTypes.ERROR);
            });
        }}
      >
        Save
      </Button>
    </div>
  );
};

const convertTypeToText = (type: CompanyEmailTemplate['type']) => {
  switch (type) {
    case 'EMAIL_TEMPLATE_TYPE_EMAIL_CHANGED':
      return 'Email Changed';
    case 'EMAIL_TEMPLATE_TYPE_PASSWORD_RESET':
      return 'Password Reset';
    case 'EMAIL_TEMPLATE_TYPE_WELCOME':
      return 'Welcome';
    case 'UNRECOGNIZED':
      return '--';
  }
};

export default EmailTemplatesList;
