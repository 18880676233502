import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Company } from '../model';
import AuthContext from './AuthContext';
import { getCompany } from '../gateway/adminGateway';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { useNavigate } from 'react-router-dom';

export const CompanyContext = React.createContext<Company | null | undefined>(null);

const CompanyContextProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const auth = useContext(AuthContext);
  const [companyInfo, setCompanyInfo] = useState<Company | null | undefined>();
  const { toaster } = useToaster();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) return;
    if (!auth.user?.company) return;
    if (companyInfo?.id !== auth.user.company) {
      let toast = !!companyInfo;
      getCompany().then(payload => {
        if (!auth.user) return;
        setCompanyInfo(payload);
        if (toast) {
          toaster('Company changed', `You are now managing data for company: ${payload.name}`, ToastMessageTypes.INFO);
          navigate('/admin');
        }
      });
    }
  }, [auth, auth.user?.company, companyInfo, companyInfo?.id, navigate, toaster]);

  return <CompanyContext.Provider value={companyInfo}>{children}</CompanyContext.Provider>;
};

export default CompanyContext;
export { CompanyContextProvider };
