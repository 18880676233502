import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import FormikTextField from '../../components/FormikTextField';
import { addCompany } from '../../gateway/adminGateway';

interface CompanyData {
  name: string;
  supportEmailAddress: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddCompanyForm: React.FC<Props> = props => {
  const initialValues = { name: '', supportEmailAddress: '' };
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (values: CompanyData) => {
    setLoading(true);
    try {
      setLoading(true);
      await addCompany(values.name, values.supportEmailAddress, []);
      // TODO there are nicer ways to refresh data
      window.location.reload();
    } catch (_) {
      //setError("Da ist etwas schiefgegangen");
    } finally {
      setLoading(false);
      props.onClose();
    }
  };

  const onCancel = () => {
    props.onClose();
  };

  const formik = useFormik<CompanyData>({
    initialValues,
    onSubmit,
  });

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.modal + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <FormikProvider value={formik}>
        <Dialog open={props.open}>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Add Company</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormikTextField name="name" label="Company name" type="text" required />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="supportEmailAddress" label="Support email address" type="email" required />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" size="medium" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="contained" size="medium" type="submit">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </FormikProvider>
    </>
  );
};

export default AddCompanyForm;
