"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               unknown
// source: geo/v1/regions.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.Region = exports.protobufPackage = void 0;
exports.regionFromJSON = regionFromJSON;
exports.regionToJSON = regionToJSON;
exports.regionToNumber = regionToNumber;
/* eslint-disable */
exports.protobufPackage = "geo.v1";
var Region;
(function (Region) {
    Region["REGION_AUSTRIA"] = "REGION_AUSTRIA";
    Region["REGION_DENMARK"] = "REGION_DENMARK";
    Region["REGION_FRANCE"] = "REGION_FRANCE";
    Region["REGION_GERMANY"] = "REGION_GERMANY";
    Region["REGION_NORWAY"] = "REGION_NORWAY";
    Region["UNRECOGNIZED"] = "UNRECOGNIZED";
})(Region || (exports.Region = Region = {}));
function regionFromJSON(object) {
    switch (object) {
        case 0:
        case "REGION_AUSTRIA":
            return Region.REGION_AUSTRIA;
        case 1:
        case "REGION_DENMARK":
            return Region.REGION_DENMARK;
        case 2:
        case "REGION_FRANCE":
            return Region.REGION_FRANCE;
        case 3:
        case "REGION_GERMANY":
            return Region.REGION_GERMANY;
        case 4:
        case "REGION_NORWAY":
            return Region.REGION_NORWAY;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Region.UNRECOGNIZED;
    }
}
function regionToJSON(object) {
    switch (object) {
        case Region.REGION_AUSTRIA:
            return "REGION_AUSTRIA";
        case Region.REGION_DENMARK:
            return "REGION_DENMARK";
        case Region.REGION_FRANCE:
            return "REGION_FRANCE";
        case Region.REGION_GERMANY:
            return "REGION_GERMANY";
        case Region.REGION_NORWAY:
            return "REGION_NORWAY";
        case Region.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function regionToNumber(object) {
    switch (object) {
        case Region.REGION_AUSTRIA:
            return 0;
        case Region.REGION_DENMARK:
            return 1;
        case Region.REGION_FRANCE:
            return 2;
        case Region.REGION_GERMANY:
            return 3;
        case Region.REGION_NORWAY:
            return 4;
        case Region.UNRECOGNIZED:
        default:
            return -1;
    }
}
