import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import * as React from 'react';
import { logout } from '../../auth/auth';
import SiteHeader from '../../components/SiteHeader';
import TableLoading from '../../components/TableLoading';
import { config } from '../../config';
import AuthContext from '../../context/AuthContext';

const Profile: React.FC = () => {
  const auth = React.useContext(AuthContext);

  return (
    <>
      <SiteHeader title={'Profile'}>
        <Button variant="outlined" size="medium" onClick={() => logout()}>
          Logout
        </Button>
      </SiteHeader>

      {auth.user === undefined && <TableLoading />}
      {auth.user !== undefined && auth.user !== null && (
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>uid</TableCell>
              <TableCell>email</TableCell>
              <TableCell>role</TableCell>
              <TableCell>token</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>{auth.user.id}</TableCell>
              <TableCell>{auth.user.email}</TableCell>
              <TableCell>{auth.user.role}</TableCell>
              <TableCell className={'e2e-shows-auth-token'}>
                <span data-token-for-e2e={auth.user.token}>
                  {auth.user.token.slice(0, 6) + '…' + auth.user.token.slice(-6)}
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}

      <Box py={2} px={4}>
        Operator management can now be found in the <a href={`${config.betaUrl}/team`}>Team page</a> of the Backoffice
        Beta app.
      </Box>
    </>
  );
};

export default Profile;
