import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as React from 'react';
import SiteHeader from '../../components/SiteHeader';
import { AuthContext } from '../../context/AuthContext';
import AddCompanyForm from './AddCompanyForm';
import { selectCompany, useCompanies } from '../../gateway/adminGateway';
import TableLoading from '../../components/TableLoading';
import ParksContext from '../../context/ParksContext';
import TenantsContext from '../../context/TenantsContext';

const AdministrateCompanies: React.FC = () => {
  const companies = useCompanies();
  const [loading, setLoading] = React.useState(false);
  const [formOpen, setFormOpen] = React.useState(false);
  const auth = React.useContext(AuthContext);
  const { forceRefreshParks } = React.useContext(ParksContext);
  const { forceRefreshTenants } = React.useContext(TenantsContext);

  const select = async (company: string) => {
    setLoading(true);
    try {
      await selectCompany(auth.user!.id, { company });
      await auth.fetchAndSetAuthUser(true);
      forceRefreshParks();
      forceRefreshTenants();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.modal + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <AddCompanyForm open={formOpen} onClose={() => setFormOpen(false)} />

      <SiteHeader title="Registered Companies">
        <Button variant="outlined" size="medium" onClick={() => setFormOpen(true)}>
          <span className={'e2e-add-company'}>Add Company</span>
        </Button>
      </SiteHeader>

      {companies === undefined && <TableLoading />}
      {companies !== undefined && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>
                  <b>Company-ID</b>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <b>Name</b>
                </Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {companies.map(company => {
              const selected = auth.user?.company === company.id;
              const style = selected ? { fontWeight: 'bold' } : undefined;

              return (
                <TableRow
                  className={selected ? 'e2e-selected' : ''}
                  key={company.id}
                  hover
                  onClick={() => select(company.id)}
                  sx={{
                    cursor: 'pointer',
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell>
                    <Typography {...style}>{company.id}</Typography>
                  </TableCell>
                  <TableCell {...style}>
                    <Typography className={'e2e-company-name'} {...style}>
                      {company.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default AdministrateCompanies;
