"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               unknown
// source: storage_units/v2/key.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateKeyStatusResponse = exports.UpdateKeyStatusRequest = exports.GetKeysResponse = exports.GetKeysRequest = exports.KeyFilter_TenantIds = exports.KeyFilter = exports.GetKeyResponse = exports.GetKeyRequest = exports.DeleteKeyRequest = exports.CreateKeyResponse = exports.CreateKeyRequest = exports.CountKeysResponse = exports.CountKeysRequest = exports.Key = exports.KeyStatus = exports.protobufPackage = void 0;
exports.keyStatusFromJSON = keyStatusFromJSON;
exports.keyStatusToJSON = keyStatusToJSON;
exports.keyStatusToNumber = keyStatusToNumber;
/* eslint-disable */
const wire_1 = require("@bufbuild/protobuf/wire");
const regions_1 = require("../../geo/v1/regions");
const timestamp_1 = require("../../google/protobuf/timestamp");
const pagination_1 = require("../../pagination/v1/pagination");
exports.protobufPackage = "storage_units.v2";
var KeyStatus;
(function (KeyStatus) {
    KeyStatus["KEY_STATUS_UNSPECIFIED"] = "KEY_STATUS_UNSPECIFIED";
    KeyStatus["KEY_STATUS_ACTIVE"] = "KEY_STATUS_ACTIVE";
    KeyStatus["KEY_STATUS_INACTIVE"] = "KEY_STATUS_INACTIVE";
    KeyStatus["UNRECOGNIZED"] = "UNRECOGNIZED";
})(KeyStatus || (exports.KeyStatus = KeyStatus = {}));
function keyStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "KEY_STATUS_UNSPECIFIED":
            return KeyStatus.KEY_STATUS_UNSPECIFIED;
        case 1:
        case "KEY_STATUS_ACTIVE":
            return KeyStatus.KEY_STATUS_ACTIVE;
        case 2:
        case "KEY_STATUS_INACTIVE":
            return KeyStatus.KEY_STATUS_INACTIVE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return KeyStatus.UNRECOGNIZED;
    }
}
function keyStatusToJSON(object) {
    switch (object) {
        case KeyStatus.KEY_STATUS_UNSPECIFIED:
            return "KEY_STATUS_UNSPECIFIED";
        case KeyStatus.KEY_STATUS_ACTIVE:
            return "KEY_STATUS_ACTIVE";
        case KeyStatus.KEY_STATUS_INACTIVE:
            return "KEY_STATUS_INACTIVE";
        case KeyStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function keyStatusToNumber(object) {
    switch (object) {
        case KeyStatus.KEY_STATUS_UNSPECIFIED:
            return 0;
        case KeyStatus.KEY_STATUS_ACTIVE:
            return 1;
        case KeyStatus.KEY_STATUS_INACTIVE:
            return 2;
        case KeyStatus.UNRECOGNIZED:
        default:
            return -1;
    }
}
function createBaseKey() {
    return {
        id: "",
        created: undefined,
        modified: undefined,
        rentalId: "",
        tenantId: "",
        status: KeyStatus.KEY_STATUS_UNSPECIFIED,
        region: regions_1.Region.REGION_AUSTRIA,
    };
}
exports.Key = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.created !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.created), writer.uint32(18).fork()).join();
        }
        if (message.modified !== undefined) {
            timestamp_1.Timestamp.encode(toTimestamp(message.modified), writer.uint32(26).fork()).join();
        }
        if (message.rentalId !== "") {
            writer.uint32(34).string(message.rentalId);
        }
        if (message.tenantId !== "") {
            writer.uint32(42).string(message.tenantId);
        }
        if (message.status !== KeyStatus.KEY_STATUS_UNSPECIFIED) {
            writer.uint32(48).int32(keyStatusToNumber(message.status));
        }
        if (message.region !== regions_1.Region.REGION_AUSTRIA) {
            writer.uint32(56).int32((0, regions_1.regionToNumber)(message.region));
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseKey();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.created = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 3: {
                    if (tag !== 26) {
                        break;
                    }
                    message.modified = fromTimestamp(timestamp_1.Timestamp.decode(reader, reader.uint32()));
                    continue;
                }
                case 4: {
                    if (tag !== 34) {
                        break;
                    }
                    message.rentalId = reader.string();
                    continue;
                }
                case 5: {
                    if (tag !== 42) {
                        break;
                    }
                    message.tenantId = reader.string();
                    continue;
                }
                case 6: {
                    if (tag !== 48) {
                        break;
                    }
                    message.status = keyStatusFromJSON(reader.int32());
                    continue;
                }
                case 7: {
                    if (tag !== 56) {
                        break;
                    }
                    message.region = (0, regions_1.regionFromJSON)(reader.int32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? globalThis.String(object.id) : "",
            created: isSet(object.created) ? fromJsonTimestamp(object.created) : undefined,
            modified: isSet(object.modified) ? fromJsonTimestamp(object.modified) : undefined,
            rentalId: isSet(object.rentalId) ? globalThis.String(object.rentalId) : "",
            tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
            status: isSet(object.status) ? keyStatusFromJSON(object.status) : KeyStatus.KEY_STATUS_UNSPECIFIED,
            region: isSet(object.region) ? (0, regions_1.regionFromJSON)(object.region) : regions_1.Region.REGION_AUSTRIA,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.created !== undefined) {
            obj.created = message.created.toISOString();
        }
        if (message.modified !== undefined) {
            obj.modified = message.modified.toISOString();
        }
        if (message.rentalId !== "") {
            obj.rentalId = message.rentalId;
        }
        if (message.tenantId !== "") {
            obj.tenantId = message.tenantId;
        }
        if (message.status !== KeyStatus.KEY_STATUS_UNSPECIFIED) {
            obj.status = keyStatusToJSON(message.status);
        }
        if (message.region !== regions_1.Region.REGION_AUSTRIA) {
            obj.region = (0, regions_1.regionToJSON)(message.region);
        }
        return obj;
    },
    create(base) {
        return exports.Key.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseKey();
        message.id = object.id ?? "";
        message.created = object.created ?? undefined;
        message.modified = object.modified ?? undefined;
        message.rentalId = object.rentalId ?? "";
        message.tenantId = object.tenantId ?? "";
        message.status = object.status ?? KeyStatus.KEY_STATUS_UNSPECIFIED;
        message.region = object.region ?? regions_1.Region.REGION_AUSTRIA;
        return message;
    },
};
function createBaseCountKeysRequest() {
    return { filter: undefined };
}
exports.CountKeysRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.filter !== undefined) {
            exports.KeyFilter.encode(message.filter, writer.uint32(10).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCountKeysRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.filter = exports.KeyFilter.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { filter: isSet(object.filter) ? exports.KeyFilter.fromJSON(object.filter) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.filter !== undefined) {
            obj.filter = exports.KeyFilter.toJSON(message.filter);
        }
        return obj;
    },
    create(base) {
        return exports.CountKeysRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCountKeysRequest();
        message.filter = (object.filter !== undefined && object.filter !== null)
            ? exports.KeyFilter.fromPartial(object.filter)
            : undefined;
        return message;
    },
};
function createBaseCountKeysResponse() {
    return { count: 0 };
}
exports.CountKeysResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.count !== 0) {
            writer.uint32(8).int32(message.count);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCountKeysResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 8) {
                        break;
                    }
                    message.count = reader.int32();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { count: isSet(object.count) ? globalThis.Number(object.count) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.count !== 0) {
            obj.count = Math.round(message.count);
        }
        return obj;
    },
    create(base) {
        return exports.CountKeysResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCountKeysResponse();
        message.count = object.count ?? 0;
        return message;
    },
};
function createBaseCreateKeyRequest() {
    return { rentalId: "", tenantId: "", status: KeyStatus.KEY_STATUS_UNSPECIFIED };
}
exports.CreateKeyRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.rentalId !== "") {
            writer.uint32(10).string(message.rentalId);
        }
        if (message.tenantId !== "") {
            writer.uint32(18).string(message.tenantId);
        }
        if (message.status !== KeyStatus.KEY_STATUS_UNSPECIFIED) {
            writer.uint32(24).int32(keyStatusToNumber(message.status));
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateKeyRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.rentalId = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.tenantId = reader.string();
                    continue;
                }
                case 3: {
                    if (tag !== 24) {
                        break;
                    }
                    message.status = keyStatusFromJSON(reader.int32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            rentalId: isSet(object.rentalId) ? globalThis.String(object.rentalId) : "",
            tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
            status: isSet(object.status) ? keyStatusFromJSON(object.status) : KeyStatus.KEY_STATUS_UNSPECIFIED,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.rentalId !== "") {
            obj.rentalId = message.rentalId;
        }
        if (message.tenantId !== "") {
            obj.tenantId = message.tenantId;
        }
        if (message.status !== KeyStatus.KEY_STATUS_UNSPECIFIED) {
            obj.status = keyStatusToJSON(message.status);
        }
        return obj;
    },
    create(base) {
        return exports.CreateKeyRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCreateKeyRequest();
        message.rentalId = object.rentalId ?? "";
        message.tenantId = object.tenantId ?? "";
        message.status = object.status ?? KeyStatus.KEY_STATUS_UNSPECIFIED;
        return message;
    },
};
function createBaseCreateKeyResponse() {
    return { key: undefined };
}
exports.CreateKeyResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.key !== undefined) {
            exports.Key.encode(message.key, writer.uint32(10).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateKeyResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.key = exports.Key.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { key: isSet(object.key) ? exports.Key.fromJSON(object.key) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.key !== undefined) {
            obj.key = exports.Key.toJSON(message.key);
        }
        return obj;
    },
    create(base) {
        return exports.CreateKeyResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseCreateKeyResponse();
        message.key = (object.key !== undefined && object.key !== null) ? exports.Key.fromPartial(object.key) : undefined;
        return message;
    },
};
function createBaseDeleteKeyRequest() {
    return { id: "" };
}
exports.DeleteKeyRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteKeyRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        return obj;
    },
    create(base) {
        return exports.DeleteKeyRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseDeleteKeyRequest();
        message.id = object.id ?? "";
        return message;
    },
};
function createBaseGetKeyRequest() {
    return { id: "" };
}
exports.GetKeyRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetKeyRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        return obj;
    },
    create(base) {
        return exports.GetKeyRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetKeyRequest();
        message.id = object.id ?? "";
        return message;
    },
};
function createBaseGetKeyResponse() {
    return { key: undefined };
}
exports.GetKeyResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.key !== undefined) {
            exports.Key.encode(message.key, writer.uint32(10).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetKeyResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.key = exports.Key.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { key: isSet(object.key) ? exports.Key.fromJSON(object.key) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.key !== undefined) {
            obj.key = exports.Key.toJSON(message.key);
        }
        return obj;
    },
    create(base) {
        return exports.GetKeyResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetKeyResponse();
        message.key = (object.key !== undefined && object.key !== null) ? exports.Key.fromPartial(object.key) : undefined;
        return message;
    },
};
function createBaseKeyFilter() {
    return {
        rentalId: undefined,
        tenantId: undefined,
        status: undefined,
        storageUnitId: undefined,
        parkId: undefined,
        tenantIds: undefined,
    };
}
exports.KeyFilter = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.rentalId !== undefined) {
            writer.uint32(10).string(message.rentalId);
        }
        if (message.tenantId !== undefined) {
            writer.uint32(18).string(message.tenantId);
        }
        if (message.status !== undefined) {
            writer.uint32(24).int32(keyStatusToNumber(message.status));
        }
        if (message.storageUnitId !== undefined) {
            writer.uint32(34).string(message.storageUnitId);
        }
        if (message.parkId !== undefined) {
            writer.uint32(42).string(message.parkId);
        }
        if (message.tenantIds !== undefined) {
            exports.KeyFilter_TenantIds.encode(message.tenantIds, writer.uint32(50).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseKeyFilter();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.rentalId = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.tenantId = reader.string();
                    continue;
                }
                case 3: {
                    if (tag !== 24) {
                        break;
                    }
                    message.status = keyStatusFromJSON(reader.int32());
                    continue;
                }
                case 4: {
                    if (tag !== 34) {
                        break;
                    }
                    message.storageUnitId = reader.string();
                    continue;
                }
                case 5: {
                    if (tag !== 42) {
                        break;
                    }
                    message.parkId = reader.string();
                    continue;
                }
                case 6: {
                    if (tag !== 50) {
                        break;
                    }
                    message.tenantIds = exports.KeyFilter_TenantIds.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            rentalId: isSet(object.rentalId) ? globalThis.String(object.rentalId) : undefined,
            tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : undefined,
            status: isSet(object.status) ? keyStatusFromJSON(object.status) : undefined,
            storageUnitId: isSet(object.storageUnitId) ? globalThis.String(object.storageUnitId) : undefined,
            parkId: isSet(object.parkId) ? globalThis.String(object.parkId) : undefined,
            tenantIds: isSet(object.tenantIds) ? exports.KeyFilter_TenantIds.fromJSON(object.tenantIds) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.rentalId !== undefined) {
            obj.rentalId = message.rentalId;
        }
        if (message.tenantId !== undefined) {
            obj.tenantId = message.tenantId;
        }
        if (message.status !== undefined) {
            obj.status = keyStatusToJSON(message.status);
        }
        if (message.storageUnitId !== undefined) {
            obj.storageUnitId = message.storageUnitId;
        }
        if (message.parkId !== undefined) {
            obj.parkId = message.parkId;
        }
        if (message.tenantIds !== undefined) {
            obj.tenantIds = exports.KeyFilter_TenantIds.toJSON(message.tenantIds);
        }
        return obj;
    },
    create(base) {
        return exports.KeyFilter.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseKeyFilter();
        message.rentalId = object.rentalId ?? undefined;
        message.tenantId = object.tenantId ?? undefined;
        message.status = object.status ?? undefined;
        message.storageUnitId = object.storageUnitId ?? undefined;
        message.parkId = object.parkId ?? undefined;
        message.tenantIds = (object.tenantIds !== undefined && object.tenantIds !== null)
            ? exports.KeyFilter_TenantIds.fromPartial(object.tenantIds)
            : undefined;
        return message;
    },
};
function createBaseKeyFilter_TenantIds() {
    return { ids: [] };
}
exports.KeyFilter_TenantIds = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        for (const v of message.ids) {
            writer.uint32(10).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseKeyFilter_TenantIds();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.ids.push(reader.string());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e) => globalThis.String(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.ids?.length) {
            obj.ids = message.ids;
        }
        return obj;
    },
    create(base) {
        return exports.KeyFilter_TenantIds.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseKeyFilter_TenantIds();
        message.ids = object.ids?.map((e) => e) || [];
        return message;
    },
};
function createBaseGetKeysRequest() {
    return { filter: undefined, pagination: undefined };
}
exports.GetKeysRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.filter !== undefined) {
            exports.KeyFilter.encode(message.filter, writer.uint32(10).fork()).join();
        }
        if (message.pagination !== undefined) {
            pagination_1.Pagination.encode(message.pagination, writer.uint32(18).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetKeysRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.filter = exports.KeyFilter.decode(reader, reader.uint32());
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.pagination = pagination_1.Pagination.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            filter: isSet(object.filter) ? exports.KeyFilter.fromJSON(object.filter) : undefined,
            pagination: isSet(object.pagination) ? pagination_1.Pagination.fromJSON(object.pagination) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.filter !== undefined) {
            obj.filter = exports.KeyFilter.toJSON(message.filter);
        }
        if (message.pagination !== undefined) {
            obj.pagination = pagination_1.Pagination.toJSON(message.pagination);
        }
        return obj;
    },
    create(base) {
        return exports.GetKeysRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetKeysRequest();
        message.filter = (object.filter !== undefined && object.filter !== null)
            ? exports.KeyFilter.fromPartial(object.filter)
            : undefined;
        message.pagination = (object.pagination !== undefined && object.pagination !== null)
            ? pagination_1.Pagination.fromPartial(object.pagination)
            : undefined;
        return message;
    },
};
function createBaseGetKeysResponse() {
    return { keys: [] };
}
exports.GetKeysResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        for (const v of message.keys) {
            exports.Key.encode(v, writer.uint32(10).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetKeysResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.keys.push(exports.Key.decode(reader, reader.uint32()));
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { keys: globalThis.Array.isArray(object?.keys) ? object.keys.map((e) => exports.Key.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.keys?.length) {
            obj.keys = message.keys.map((e) => exports.Key.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return exports.GetKeysResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseGetKeysResponse();
        message.keys = object.keys?.map((e) => exports.Key.fromPartial(e)) || [];
        return message;
    },
};
function createBaseUpdateKeyStatusRequest() {
    return { id: "", status: KeyStatus.KEY_STATUS_UNSPECIFIED };
}
exports.UpdateKeyStatusRequest = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.status !== KeyStatus.KEY_STATUS_UNSPECIFIED) {
            writer.uint32(16).int32(keyStatusToNumber(message.status));
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateKeyStatusRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 16) {
                        break;
                    }
                    message.status = keyStatusFromJSON(reader.int32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? globalThis.String(object.id) : "",
            status: isSet(object.status) ? keyStatusFromJSON(object.status) : KeyStatus.KEY_STATUS_UNSPECIFIED,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.status !== KeyStatus.KEY_STATUS_UNSPECIFIED) {
            obj.status = keyStatusToJSON(message.status);
        }
        return obj;
    },
    create(base) {
        return exports.UpdateKeyStatusRequest.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseUpdateKeyStatusRequest();
        message.id = object.id ?? "";
        message.status = object.status ?? KeyStatus.KEY_STATUS_UNSPECIFIED;
        return message;
    },
};
function createBaseUpdateKeyStatusResponse() {
    return { key: undefined };
}
exports.UpdateKeyStatusResponse = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.key !== undefined) {
            exports.Key.encode(message.key, writer.uint32(10).fork()).join();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateKeyStatusResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.key = exports.Key.decode(reader, reader.uint32());
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { key: isSet(object.key) ? exports.Key.fromJSON(object.key) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.key !== undefined) {
            obj.key = exports.Key.toJSON(message.key);
        }
        return obj;
    },
    create(base) {
        return exports.UpdateKeyStatusResponse.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseUpdateKeyStatusResponse();
        message.key = (object.key !== undefined && object.key !== null) ? exports.Key.fromPartial(object.key) : undefined;
        return message;
    },
};
function toTimestamp(date) {
    const seconds = Math.trunc(date.getTime() / 1_000);
    const nanos = (date.getTime() % 1_000) * 1_000_000;
    return { seconds, nanos };
}
function fromTimestamp(t) {
    let millis = (t.seconds || 0) * 1_000;
    millis += (t.nanos || 0) / 1_000_000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(timestamp_1.Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
