// --- external imports
import { UsersThree } from 'phosphor-react';
import * as React from 'react';

// --- helpers
import { Link, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { config } from '../../../config';
import { colors } from '../../../theming/colors';

const TeamItem: React.FC = () => {
  return (
    <div>
      <Link
        href={`${config.betaUrl}/team`}
        color={colors.white}
        sx={{
          textDecoration: 'none',
          color: 'white',
          '&:visited': {
            color: 'white',
          },
        }}
      >
        <ListItem
          button
          sx={{
            marginY: 1.5,
            color: 'indigo',
          }}
        >
          <ListItemIcon sx={{ justifyContent: 'center' }}>
            <UsersThree color={'white'} size={20} />
          </ListItemIcon>
          <ListItemText sx={{ color: 'white' }} primary={<b>Team</b>} />
        </ListItem>
      </Link>
    </div>
  );
};

export default TeamItem;
