import { Drawer, List } from '@mui/material';
import * as React from 'react';
import AdminItem from './items/AdminItem';
import DashboardItem from './items/DashboardItem';
import EmailsItem from './items/EmailsItem';
import ParkMonitoringDashboardItem from './items/ParkMonitoringDashboardItem';
import ParksItem from './items/ParksItem';
import ProfileItem from './items/ProfileItem';
import TenantsItem from './items/TenantsItem';
import { checkPermissionsForResource } from '@ivy/auth';
import AuthContext from '../../context/AuthContext';
import TeamItem from './items/TeamItem';

interface Props {
  width: number;
}

const Sidebar: React.FunctionComponent<Props> = ({ width }) => {
  const auth = React.useContext(AuthContext);
  return (
    <Drawer
      sx={{
        width: width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: width,
          boxSizing: 'border-box',
          background: 'linear-gradient(0.18deg, #000000 57.96%, #000000 57.96%, #1F2234 99.84%)',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <img src="/images/ivy.svg" alt="logo" style={{ width: 108, marginLeft: 32, marginTop: 40 }} />
      <>
        <List
          sx={{
            marginTop: 4,
          }}
        >
          <DashboardItem />
          {checkPermissionsForResource(auth.user?.token!, 'park', 'read') && <ParksItem />}
          {checkPermissionsForResource(auth.user?.token!, 'user', 'read') && <TenantsItem />}
          {checkPermissionsForResource(auth.user?.token!, 'company', 'read') ? <EmailsItem /> : null}
          <ParkMonitoringDashboardItem />
          <TeamItem />
        </List>
        <div style={{ flex: 1 }} />
        <List>
          <ProfileItem />
          {checkPermissionsForResource(auth.user?.token!, 'company', 'create') && <AdminItem />}
        </List>
      </>
    </Drawer>
  );
};

export default Sidebar;
