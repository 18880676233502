// --- external imports
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Warning, X } from 'phosphor-react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';

// --- components
import { useToaster, ToastMessageTypes } from '@ivy/toaster';

// --- contexts
import CompanyContext from '../../../context/CompanyContext';
import TenantsContext from '../../../context/TenantsContext';

// --- api
import { grantAccess, revokeKey } from '../../../gateway/storageUnitKeys/storageUnitKeysGateway';
import { createTenant, putTenant } from '../../../gateway/tenantGateway';
import TenantDetails from './TenantDetails';
import TenantFormKeys from './TenantFormKeys';

// --- helpers
import { Rental, TenantFormData } from './TenantFormTypes';
import { StorageUnitKey, Tenant } from '../../../model';
import { isEqual } from 'lodash';

// --- styles
import { colors } from '../../../theming/colors';
import ParksContext from '../../../context/ParksContext';
import { useStorageUnits } from '../../../gateway/storageUnit/get';
import { CompanyEmailTemplate } from '../../../gateway/adminGateway';

interface TenantFormProps {
  title: string;
  tenant?: Tenant;
  rentals: Rental[];
  keys: StorageUnitKey[];
}

const TenantForm: React.FunctionComponent<TenantFormProps> = ({ tenant, rentals, title, keys: initialKeys }) => {
  const emptyTenant: TenantFormData = {
    tenant: {
      firstName: '',
      lastName: '',
      email: '',
      languagePreference: 'UNRECOGNIZED',
    },
    rentals: [],
    keys: [],
  };

  const initialValues = tenant ? assembleFormData(tenant, rentals, initialKeys) : emptyTenant;
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: initialValues,
  });

  const keys = watch('keys');

  const [submitting, setSubmitting] = React.useState(false);
  const [valuesUnchanged, setValuesUnchanged] = React.useState(true);
  const [changeCounter, setChangeCounter] = React.useState(0);
  const [templateExists, setTemplateExists] = React.useState(false);
  const company = React.useContext(CompanyContext);
  const { forceRefreshTenants } = React.useContext(TenantsContext);
  const { toaster } = useToaster();
  const navigate = useNavigate();

  const parks = React.useContext(ParksContext).parks?.sort((a, b) => a.name.localeCompare(b.name));

  const storageUnits = useStorageUnits()?.sort((a, b) =>
    a.visualId && b.visualId ? a.visualId.localeCompare(b.visualId) : 0,
  );

  const isFormChanged = (initialValues: TenantFormData, values: TenantFormData): Boolean => {
    return !isEqual(initialValues, values) || !isEqual(keys, initialKeys);
  };

  const saveButtonDisabled = React.useMemo(() => {
    return submitting || valuesUnchanged || !templateExists || !parks || !storageUnits;
  }, [submitting, valuesUnchanged, templateExists, parks, storageUnits]);

  const onCloseDialog = React.useCallback(() => {
    navigate('/tenants', { replace: true });
  }, [navigate]);

  const onSubmit = React.useCallback(
    async (values: TenantFormData) => {
      setSubmitting(true);
      let tenantId = tenant?.id || '';
      try {
        if (!isEqual(initialValues, values)) {
          tenantId = await updateTenant(values.tenant);
        }
        if (tenantId) {
          //The following line handles the case where the user accidentally added the access to the storage unit and removed it before saving
          const realKeys = values.keys.filter(key => !key.removed || initialKeys.find(k => k.id === key.id));
          await updateKeys(realKeys, tenantId);
        }

        forceRefreshTenants();
        onCloseDialog();
      } catch (error) {
        toaster(
          'Sorry!',
          `Could not update tenant data, ${error.response.data.message || error.message}`,
          ToastMessageTypes.ERROR,
        );
      } finally {
        setSubmitting(false);
      }
    },
    [initialValues, initialKeys, toaster, forceRefreshTenants, onCloseDialog, tenant?.id],
  );

  React.useEffect(() => {
    const emailChanged = getValues().tenant.email !== initialValues.tenant.email;
    if (emailChanged && tenant?.id) {
      const templateType: CompanyEmailTemplate['type'] = tenant?.id
        ? 'EMAIL_TEMPLATE_TYPE_EMAIL_CHANGED'
        : 'EMAIL_TEMPLATE_TYPE_WELCOME';
      // this can be replaced with a backoffice-service call by exposing a new end-point
      const template = company?.hubspotEmailTemplates?.find(template => template.type === templateType);

      const lang = getValues().tenant.languagePreference;

      switch (lang) {
        case 'LANGUAGE_PREFERENCE_DE':
          if (template?.hubspotIdDe?.length) setTemplateExists(true);
          else setTemplateExists(false);
          break;

        case 'LANGUAGE_PREFERENCE_FR':
          if (template?.hubspotIdFr?.length) setTemplateExists(true);
          else setTemplateExists(false);
          break;

        default:
          setTemplateExists(false);
      }
    } else {
      setTemplateExists(true);
    }
  }, [setTemplateExists, company, getValues, initialValues.tenant.email, changeCounter, tenant?.id]);

  const emailType = tenant?.id ? 'Email Changed' : 'Welcome';

  return (
    <>
      {submitting && (
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.modal + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Dialog
        open={true}
        hideBackdrop={tenant?.id ? true : false}
        sx={{
          '& .MuiPaper-root': { width: '100%', maxWidth: 1200 },
          '& .MuiDialogActions-root': { padding: 2 },
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          onBlur={() => {
            setValuesUnchanged(!isFormChanged(initialValues, getValues()));
            setChangeCounter(changeCounter + 1);
          }}
          onChange={() => {
            setValuesUnchanged(!isFormChanged(initialValues, getValues()));
            setChangeCounter(changeCounter + 1);
          }}
        >
          <DialogTitle variant="h2">
            {title}
            <IconButton
              onClick={onCloseDialog}
              sx={{
                position: 'absolute',
                right: 12,
                top: 14,
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Typography variant="h5">Name</Typography>
            <Box m={2} />
            <TenantDetails control={control} errors={errors} />
            {!templateExists && (
              <div
                style={{
                  color: colors.signalWarning,
                  display: 'grid',
                  placeItems: 'center',
                  gridTemplateColumns: 'auto auto auto',
                  width: 'max-content',
                }}
              >
                <Warning style={{ marginRight: '0.25em' }} /> Please set the &apos;{emailType}&apos; template ID
                <Link style={{ textDecoration: 'none', color: colors.primary, marginLeft: '0.24em' }} to={'/emails'}>
                  here
                </Link>
              </div>
            )}
            <Box m={4} />
            <Divider />
            <Box m={2} />
            <Typography variant="h5">Keys</Typography>
            <Box m={2} />
            <TenantFormKeys
              formControl={control}
              storageUnitKeys={keys}
              setKeys={k => {
                setValue('keys', k);
                setValuesUnchanged(!isFormChanged(initialValues, getValues()));
              }}
              parks={parks}
              storageUnits={storageUnits}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" size="medium" onClick={onCloseDialog}>
              Cancel
            </Button>
            <Button variant="contained" size="medium" type="submit" disabled={saveButtonDisabled}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const assembleFormData = (tenant: Tenant, rentals: Rental[], keys: StorageUnitKey[]): TenantFormData => ({
  tenant,
  rentals: rentals.map(entry => ({
    ...entry,
    storageUnitVisualId: entry.storageUnitVisualId || '',
  })),
  keys,
});

async function updateTenant(tenant: TenantFormData['tenant']) {
  let id: string | undefined = undefined;
  if (tenant.id) {
    await putTenant({
      ...tenant,
      id: tenant.id,
    });
    id = tenant.id;
  } else {
    id = await createTenant(tenant);
  }
  return id;
}

async function updateKeys(keys: TenantFormData['keys'], tenantId: string) {
  const addedKeys = keys.filter(key => !key.id);
  const removedKeys = keys.filter(key => key.removed);

  console.log('Add: ', addedKeys);
  console.log('Remove: ', removedKeys);

  await Promise.all([
    ...removedKeys.map(key => revokeKey(key.storageUnitId, key.id!)),
    ...addedKeys.map(key => grantAccess(key.storageUnitId, tenantId)),
  ]);
}

export default TenantForm;
