import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import ReactHookFormInput, { ReactHookFormInputType, reEmail } from '../../../components/form/ReactHookFormInput';
import { Control, FieldErrors } from 'react-hook-form';
import { colors } from '../../../theming/colors';
import { LanguagePreference } from '../../../model';
import { getLanguagePreferenceName } from '../../../util/util';
import { TenantFormData } from './TenantFormTypes';
import CompanyContext from '../../../context/CompanyContext';

type TenantDetailsFormProps = {
  control: Control<TenantFormData>;
  errors: FieldErrors<TenantFormData>;
};

const TenantDetails: React.FC<TenantDetailsFormProps> = ({ control, errors }) => {
  const companyInfo = React.useContext(CompanyContext);

  const languagePreferenceOptions: Array<{ name: string; value: LanguagePreference }> = [
    { name: getLanguagePreferenceName('LANGUAGE_PREFERENCE_DE'), value: 'LANGUAGE_PREFERENCE_DE' },
    { name: getLanguagePreferenceName('LANGUAGE_PREFERENCE_FR'), value: 'LANGUAGE_PREFERENCE_FR' },
  ];

  const validateHubsoptEmailTemplate = (value: LanguagePreference): string | boolean => {
    // if company does not have hubspot integration, no need to validate
    if (!companyInfo?.hubspotEmailTemplates) return true;
    // if value is unrecognized, new tenant is being created
    if (value === 'UNRECOGNIZED') return 'Please select a language preference';
    // if value is the same as the initial value, no need to validate since it's not changed
    if (control._defaultValues.tenant?.languagePreference === value) return true;

    // if company does not have any email templates, no need to validate
    if (companyInfo?.hubspotEmailTemplates.length === 0) return true;

    const hubspotEmailTemplate = companyInfo?.hubspotEmailTemplates?.find(
      template => template.type === 'EMAIL_TEMPLATE_TYPE_WELCOME',
    );

    if (!hubspotEmailTemplate) return 'No Email Templates Found';
    if (value === 'LANGUAGE_PREFERENCE_DE' && !hubspotEmailTemplate.hubspotIdDe)
      return 'No Hubspot email integration template ID Found for Preferred Language DE';

    if (value === 'LANGUAGE_PREFERENCE_FR' && !hubspotEmailTemplate.hubspotIdFr)
      return 'No Hubspot email integration template ID Found for Preferred Language FR';

    return true;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={3}>
        <ReactHookFormInput
          control={control}
          name="tenant.firstName"
          rules={{ required: true }}
          label="First Name"
          type={ReactHookFormInputType.text}
        />
        {errors.tenant?.firstName && (
          <Typography color={colors.signalAlert} fontSize={12}>
            {errors.tenant.firstName.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} lg={3}>
        <ReactHookFormInput
          control={control}
          name="tenant.lastName"
          label="Last Name"
          type={ReactHookFormInputType.text}
          rules={{ required: true }}
        />
        {errors.tenant?.lastName && (
          <Typography color={colors.signalAlert} fontSize={12}>
            {errors.tenant.lastName.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} lg={3}>
        <ReactHookFormInput
          control={control}
          name="tenant.email"
          label="Email"
          type={ReactHookFormInputType.email}
          rules={{ required: true, pattern: { value: reEmail, message: 'Invalid email address' } }}
        />
        {errors.tenant?.email && (
          <Typography color={colors.signalAlert} fontSize={12}>
            {errors.tenant.email.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} lg={3}>
        <ReactHookFormInput
          control={control}
          name="tenant.languagePreference"
          label="Language preference"
          type={ReactHookFormInputType.select}
          options={languagePreferenceOptions}
          rules={{ required: true, validate: validateHubsoptEmailTemplate }}
        />
        {errors.tenant?.languagePreference && (
          <Typography color={colors.signalAlert} fontSize={12}>
            {errors.tenant.languagePreference.message}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default TenantDetails;
