import { useEffect, useState } from 'react';
import { components } from '../../gen/backoffice-service';
import { getAuthToken } from '../auth/auth';
import { Company } from '../model';
import { get, post, put } from './requestUtils';

export type PostCompanyBody = components['schemas']['AdminPostCompanyBody'];
export type PutUserBody = components['schemas']['AdminPutUserBody'];

export type CompanyEmailTemplate = components['schemas']['HubspotEmailTemplate'];

export const selectCompany = async (userId: string, body: PutUserBody): Promise<void> => {
  const token = await getAuthToken();
  await put(`/admin/user/${userId}`, body, token);
};

export const useCompanies = (): Company[] | undefined => {
  const [data, setData] = useState<Company[] | undefined>(undefined);
  useEffect(() => {
    getCompanies().then(data => {
      setData(data);
    });
  }, []);
  return data;
};

export const getCompanies = async (): Promise<Company[]> => {
  const token = await getAuthToken();
  const response = await get(`/admin/companies`, token);
  return response.data.companies;
};

export const getCompany = async (): Promise<Company> => {
  const token = await getAuthToken();
  const response = await get(`/admin/company`, token);
  return response.data;
};

export const addCompany = async (
  name: string,
  supportEmailAdress: string,
  emailTemplates: CompanyEmailTemplate[],
): Promise<Company> => {
  const body: PostCompanyBody = { name, supportEmailAdress, emailTemplates };
  const token = await getAuthToken();
  const response = await post(`/admin/companies`, body, token);
  return response.data;
};

export const updateCompany = async (id: string, body: PostCompanyBody): Promise<void> => {
  const token = await getAuthToken();
  await put(`/admin/company/${id}`, body, token);
};
