"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               unknown
// source: users/v2/auth.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefreshToken = exports.AuthToken = exports.AuthenticatedUser = exports.AuthError = exports.UserRole = exports.protobufPackage = void 0;
exports.userRoleFromJSON = userRoleFromJSON;
exports.userRoleToJSON = userRoleToJSON;
exports.userRoleToNumber = userRoleToNumber;
exports.authErrorFromJSON = authErrorFromJSON;
exports.authErrorToJSON = authErrorToJSON;
exports.authErrorToNumber = authErrorToNumber;
/* eslint-disable */
const wire_1 = require("@bufbuild/protobuf/wire");
const regions_1 = require("../../geo/v1/regions");
exports.protobufPackage = "users.v2";
var UserRole;
(function (UserRole) {
    UserRole["USER_ROLE_UNSPECIFIED"] = "USER_ROLE_UNSPECIFIED";
    UserRole["USER_ROLE_SUPER_ADMIN"] = "USER_ROLE_SUPER_ADMIN";
    UserRole["USER_ROLE_ADMIN"] = "USER_ROLE_ADMIN";
    UserRole["USER_ROLE_SERVICE_TEAM"] = "USER_ROLE_SERVICE_TEAM";
    UserRole["USER_ROLE_FACILITY_MANAGER"] = "USER_ROLE_FACILITY_MANAGER";
    UserRole["USER_ROLE_FACILITY_MANAGER_EXTERNAL"] = "USER_ROLE_FACILITY_MANAGER_EXTERNAL";
    UserRole["USER_ROLE_TENANT"] = "USER_ROLE_TENANT";
    UserRole["UNRECOGNIZED"] = "UNRECOGNIZED";
})(UserRole || (exports.UserRole = UserRole = {}));
function userRoleFromJSON(object) {
    switch (object) {
        case 0:
        case "USER_ROLE_UNSPECIFIED":
            return UserRole.USER_ROLE_UNSPECIFIED;
        case 1:
        case "USER_ROLE_SUPER_ADMIN":
            return UserRole.USER_ROLE_SUPER_ADMIN;
        case 2:
        case "USER_ROLE_ADMIN":
            return UserRole.USER_ROLE_ADMIN;
        case 3:
        case "USER_ROLE_SERVICE_TEAM":
            return UserRole.USER_ROLE_SERVICE_TEAM;
        case 4:
        case "USER_ROLE_FACILITY_MANAGER":
            return UserRole.USER_ROLE_FACILITY_MANAGER;
        case 5:
        case "USER_ROLE_FACILITY_MANAGER_EXTERNAL":
            return UserRole.USER_ROLE_FACILITY_MANAGER_EXTERNAL;
        case 6:
        case "USER_ROLE_TENANT":
            return UserRole.USER_ROLE_TENANT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return UserRole.UNRECOGNIZED;
    }
}
function userRoleToJSON(object) {
    switch (object) {
        case UserRole.USER_ROLE_UNSPECIFIED:
            return "USER_ROLE_UNSPECIFIED";
        case UserRole.USER_ROLE_SUPER_ADMIN:
            return "USER_ROLE_SUPER_ADMIN";
        case UserRole.USER_ROLE_ADMIN:
            return "USER_ROLE_ADMIN";
        case UserRole.USER_ROLE_SERVICE_TEAM:
            return "USER_ROLE_SERVICE_TEAM";
        case UserRole.USER_ROLE_FACILITY_MANAGER:
            return "USER_ROLE_FACILITY_MANAGER";
        case UserRole.USER_ROLE_FACILITY_MANAGER_EXTERNAL:
            return "USER_ROLE_FACILITY_MANAGER_EXTERNAL";
        case UserRole.USER_ROLE_TENANT:
            return "USER_ROLE_TENANT";
        case UserRole.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function userRoleToNumber(object) {
    switch (object) {
        case UserRole.USER_ROLE_UNSPECIFIED:
            return 0;
        case UserRole.USER_ROLE_SUPER_ADMIN:
            return 1;
        case UserRole.USER_ROLE_ADMIN:
            return 2;
        case UserRole.USER_ROLE_SERVICE_TEAM:
            return 3;
        case UserRole.USER_ROLE_FACILITY_MANAGER:
            return 4;
        case UserRole.USER_ROLE_FACILITY_MANAGER_EXTERNAL:
            return 5;
        case UserRole.USER_ROLE_TENANT:
            return 6;
        case UserRole.UNRECOGNIZED:
        default:
            return -1;
    }
}
var AuthError;
(function (AuthError) {
    AuthError["AUTH_ERROR_UNSPECIFIED"] = "AUTH_ERROR_UNSPECIFIED";
    AuthError["AUTH_ERROR_EMAIL_NOT_FOUND"] = "AUTH_ERROR_EMAIL_NOT_FOUND";
    AuthError["AUTH_ERROR_INVALID_PASSWORD"] = "AUTH_ERROR_INVALID_PASSWORD";
    AuthError["AUTH_ERROR_INVALID_EMAIL"] = "AUTH_ERROR_INVALID_EMAIL";
    AuthError["AUTH_ERROR_USER_DISABLED"] = "AUTH_ERROR_USER_DISABLED";
    AuthError["AUTH_ERROR_TOO_MANY_ATTEMPTS"] = "AUTH_ERROR_TOO_MANY_ATTEMPTS";
    AuthError["AUTH_ERROR_TOKEN_EXPIRED"] = "AUTH_ERROR_TOKEN_EXPIRED";
    AuthError["AUTH_ERROR_USER_NOT_FOUND"] = "AUTH_ERROR_USER_NOT_FOUND";
    AuthError["AUTH_ERROR_INVALID_REFRESH_TOKEN"] = "AUTH_ERROR_INVALID_REFRESH_TOKEN";
    AuthError["AUTH_ERROR_MISSING_REFRESH_TOKEN"] = "AUTH_ERROR_MISSING_REFRESH_TOKEN";
    AuthError["AUTH_ERROR_WEAK_PASSWORD"] = "AUTH_ERROR_WEAK_PASSWORD";
    AuthError["AUTH_ERROR_INVALID_ID_TOKEN"] = "AUTH_ERROR_INVALID_ID_TOKEN";
    AuthError["UNRECOGNIZED"] = "UNRECOGNIZED";
})(AuthError || (exports.AuthError = AuthError = {}));
function authErrorFromJSON(object) {
    switch (object) {
        case 0:
        case "AUTH_ERROR_UNSPECIFIED":
            return AuthError.AUTH_ERROR_UNSPECIFIED;
        case 1:
        case "AUTH_ERROR_EMAIL_NOT_FOUND":
            return AuthError.AUTH_ERROR_EMAIL_NOT_FOUND;
        case 2:
        case "AUTH_ERROR_INVALID_PASSWORD":
            return AuthError.AUTH_ERROR_INVALID_PASSWORD;
        case 3:
        case "AUTH_ERROR_INVALID_EMAIL":
            return AuthError.AUTH_ERROR_INVALID_EMAIL;
        case 4:
        case "AUTH_ERROR_USER_DISABLED":
            return AuthError.AUTH_ERROR_USER_DISABLED;
        case 5:
        case "AUTH_ERROR_TOO_MANY_ATTEMPTS":
            return AuthError.AUTH_ERROR_TOO_MANY_ATTEMPTS;
        case 6:
        case "AUTH_ERROR_TOKEN_EXPIRED":
            return AuthError.AUTH_ERROR_TOKEN_EXPIRED;
        case 7:
        case "AUTH_ERROR_USER_NOT_FOUND":
            return AuthError.AUTH_ERROR_USER_NOT_FOUND;
        case 8:
        case "AUTH_ERROR_INVALID_REFRESH_TOKEN":
            return AuthError.AUTH_ERROR_INVALID_REFRESH_TOKEN;
        case 9:
        case "AUTH_ERROR_MISSING_REFRESH_TOKEN":
            return AuthError.AUTH_ERROR_MISSING_REFRESH_TOKEN;
        case 10:
        case "AUTH_ERROR_WEAK_PASSWORD":
            return AuthError.AUTH_ERROR_WEAK_PASSWORD;
        case 11:
        case "AUTH_ERROR_INVALID_ID_TOKEN":
            return AuthError.AUTH_ERROR_INVALID_ID_TOKEN;
        case -1:
        case "UNRECOGNIZED":
        default:
            return AuthError.UNRECOGNIZED;
    }
}
function authErrorToJSON(object) {
    switch (object) {
        case AuthError.AUTH_ERROR_UNSPECIFIED:
            return "AUTH_ERROR_UNSPECIFIED";
        case AuthError.AUTH_ERROR_EMAIL_NOT_FOUND:
            return "AUTH_ERROR_EMAIL_NOT_FOUND";
        case AuthError.AUTH_ERROR_INVALID_PASSWORD:
            return "AUTH_ERROR_INVALID_PASSWORD";
        case AuthError.AUTH_ERROR_INVALID_EMAIL:
            return "AUTH_ERROR_INVALID_EMAIL";
        case AuthError.AUTH_ERROR_USER_DISABLED:
            return "AUTH_ERROR_USER_DISABLED";
        case AuthError.AUTH_ERROR_TOO_MANY_ATTEMPTS:
            return "AUTH_ERROR_TOO_MANY_ATTEMPTS";
        case AuthError.AUTH_ERROR_TOKEN_EXPIRED:
            return "AUTH_ERROR_TOKEN_EXPIRED";
        case AuthError.AUTH_ERROR_USER_NOT_FOUND:
            return "AUTH_ERROR_USER_NOT_FOUND";
        case AuthError.AUTH_ERROR_INVALID_REFRESH_TOKEN:
            return "AUTH_ERROR_INVALID_REFRESH_TOKEN";
        case AuthError.AUTH_ERROR_MISSING_REFRESH_TOKEN:
            return "AUTH_ERROR_MISSING_REFRESH_TOKEN";
        case AuthError.AUTH_ERROR_WEAK_PASSWORD:
            return "AUTH_ERROR_WEAK_PASSWORD";
        case AuthError.AUTH_ERROR_INVALID_ID_TOKEN:
            return "AUTH_ERROR_INVALID_ID_TOKEN";
        case AuthError.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function authErrorToNumber(object) {
    switch (object) {
        case AuthError.AUTH_ERROR_UNSPECIFIED:
            return 0;
        case AuthError.AUTH_ERROR_EMAIL_NOT_FOUND:
            return 1;
        case AuthError.AUTH_ERROR_INVALID_PASSWORD:
            return 2;
        case AuthError.AUTH_ERROR_INVALID_EMAIL:
            return 3;
        case AuthError.AUTH_ERROR_USER_DISABLED:
            return 4;
        case AuthError.AUTH_ERROR_TOO_MANY_ATTEMPTS:
            return 5;
        case AuthError.AUTH_ERROR_TOKEN_EXPIRED:
            return 6;
        case AuthError.AUTH_ERROR_USER_NOT_FOUND:
            return 7;
        case AuthError.AUTH_ERROR_INVALID_REFRESH_TOKEN:
            return 8;
        case AuthError.AUTH_ERROR_MISSING_REFRESH_TOKEN:
            return 9;
        case AuthError.AUTH_ERROR_WEAK_PASSWORD:
            return 10;
        case AuthError.AUTH_ERROR_INVALID_ID_TOKEN:
            return 11;
        case AuthError.UNRECOGNIZED:
        default:
            return -1;
    }
}
function createBaseAuthenticatedUser() {
    return { id: "", email: "", companyId: "", role: UserRole.USER_ROLE_UNSPECIFIED, regions: [] };
}
exports.AuthenticatedUser = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.email !== "") {
            writer.uint32(18).string(message.email);
        }
        if (message.companyId !== "") {
            writer.uint32(26).string(message.companyId);
        }
        if (message.role !== UserRole.USER_ROLE_UNSPECIFIED) {
            writer.uint32(32).int32(userRoleToNumber(message.role));
        }
        writer.uint32(42).fork();
        for (const v of message.regions) {
            writer.int32((0, regions_1.regionToNumber)(v));
        }
        writer.join();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAuthenticatedUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                }
                case 2: {
                    if (tag !== 18) {
                        break;
                    }
                    message.email = reader.string();
                    continue;
                }
                case 3: {
                    if (tag !== 26) {
                        break;
                    }
                    message.companyId = reader.string();
                    continue;
                }
                case 4: {
                    if (tag !== 32) {
                        break;
                    }
                    message.role = userRoleFromJSON(reader.int32());
                    continue;
                }
                case 5: {
                    if (tag === 40) {
                        message.regions.push((0, regions_1.regionFromJSON)(reader.int32()));
                        continue;
                    }
                    if (tag === 42) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.regions.push((0, regions_1.regionFromJSON)(reader.int32()));
                        }
                        continue;
                    }
                    break;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? globalThis.String(object.id) : "",
            email: isSet(object.email) ? globalThis.String(object.email) : "",
            companyId: isSet(object.companyId) ? globalThis.String(object.companyId) : "",
            role: isSet(object.role) ? userRoleFromJSON(object.role) : UserRole.USER_ROLE_UNSPECIFIED,
            regions: globalThis.Array.isArray(object?.regions) ? object.regions.map((e) => (0, regions_1.regionFromJSON)(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.email !== "") {
            obj.email = message.email;
        }
        if (message.companyId !== "") {
            obj.companyId = message.companyId;
        }
        if (message.role !== UserRole.USER_ROLE_UNSPECIFIED) {
            obj.role = userRoleToJSON(message.role);
        }
        if (message.regions?.length) {
            obj.regions = message.regions.map((e) => (0, regions_1.regionToJSON)(e));
        }
        return obj;
    },
    create(base) {
        return exports.AuthenticatedUser.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAuthenticatedUser();
        message.id = object.id ?? "";
        message.email = object.email ?? "";
        message.companyId = object.companyId ?? "";
        message.role = object.role ?? UserRole.USER_ROLE_UNSPECIFIED;
        message.regions = object.regions?.map((e) => e) || [];
        return message;
    },
};
function createBaseAuthToken() {
    return { authToken: "" };
}
exports.AuthToken = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.authToken !== "") {
            writer.uint32(10).string(message.authToken);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAuthToken();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.authToken = reader.string();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { authToken: isSet(object.authToken) ? globalThis.String(object.authToken) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.authToken !== "") {
            obj.authToken = message.authToken;
        }
        return obj;
    },
    create(base) {
        return exports.AuthToken.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAuthToken();
        message.authToken = object.authToken ?? "";
        return message;
    },
};
function createBaseRefreshToken() {
    return { refreshToken: "" };
}
exports.RefreshToken = {
    encode(message, writer = new wire_1.BinaryWriter()) {
        if (message.refreshToken !== "") {
            writer.uint32(10).string(message.refreshToken);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof wire_1.BinaryReader ? input : new wire_1.BinaryReader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRefreshToken();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (tag !== 10) {
                        break;
                    }
                    message.refreshToken = reader.string();
                    continue;
                }
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skip(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { refreshToken: isSet(object.refreshToken) ? globalThis.String(object.refreshToken) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.refreshToken !== "") {
            obj.refreshToken = message.refreshToken;
        }
        return obj;
    },
    create(base) {
        return exports.RefreshToken.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseRefreshToken();
        message.refreshToken = object.refreshToken ?? "";
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
